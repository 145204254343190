.bp4-tabs {
	height: calc(100% - 80px);
}

.bp4-tab-list {
	height: 50px;
}

.bp4-tab-panel {
	height: calc(100% - 50px);
    margin-top: 0px;
	display: flex;
	justify-content: left;
	width: 100%;
	overflow: auto;
}

.w-100 {
	width: 100%;
}

.h-100 {
	height: 100%;
}


.vertical-flex {
	display: flex;
	flex-direction: column;
	align-items: center;
}