.foc-upload_label {
	cursor: pointer;
}

.foc-upload_input {
	display: none;
}

.foc-upload-container a {
	text-decoration: none;
	color: inherit;
}
.foc-upload-container {
	width: 98%;
	border: 2px dashed #D8D8D8;
	padding: 4em;
	text-align: center;
	border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.foc-upload-list-container {
	width: 98%;
	margin-top: 3em;
	color: #5B5B5B;
	padding-left: 1em;
}


.foc-upload-list-row {
	width: 100%;
	display: inline-block;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
}

.foc-upload-list-label {
	float: left;
	margin-right: 3em;
	margin-bottom: 1em;
}

.foc-upload-list-progress {
	float: left;
}