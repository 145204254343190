.tabHolder {
  height: 100%;
}

.plotly-plot {
  height: 100%;
  width: 100%;
  min-width: 600px;
  max-width: 1200px;
}

.plots-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.plot-size-limit {
  width: 100%;
  min-width: 750px;
  max-width: 1500px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.plots-tab {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.focusoc-plot {
  width: 100%;
  display: flex;
  justify-content: center;
}

.focusoc-plot-CAM {
  height: 600px;
}

.plot-container {
  height: 700px;
}
