.xml-content {
  height: 100%;
  width: 95%;
  overflow: auto;
  text-align: left;
  padding: 10px;
  margin: 0;
}

.xml-container {
  width: 100%;
  padding: 10px 0px;
}

.xml-border {
    border: solid;
    border-radius: 2px;
    border-width: 1px;
    border-color: #dfdfdf;
}
