:focus {
  outline: none;
  outline-offset: 0;
}

.foc-main_container {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  position: relative;
  background-color: rgba(255, 255, 255, 1);
}

.foc-table-container {
  height: calc(100% - 100px);
}

.foc-tab-content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.layout-content {
  margin-bottom: 0.5em;
}

.foc-content {
  width: 100%;
  height: 100%;
  padding-left: 60px;
  padding-right: 40px;
  padding-bottom: 20px;
  position: absolute;
}

.foc-clickable {
  cursor: pointer;
}

.foc-page_icon {
  vertical-align: middle !important;
  margin-left: 3px;
}

.foc-page_title {
  position: relative;
  padding: 30px 0px 20px 0px;
  overflow: visible;
  width: 100%;
  height: 80px;
  white-space: nowrap;
  text-align: left;
  font-family: Verdana;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: rgba(0, 0, 0, 1);
}

.foc-page_subtitle {
  position: relative;
  padding: 20px 0px 10px 0px;
  overflow: visible;
  width: 100%;
  height: 40px;
  white-space: nowrap;
  text-align: left;
  font-family: Verdana;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
}

.center-aligned {
  align-items: center;
}
.left-aligned {
  align-items: left;
}

.foc-detail-list-row {
  font-size: 14px;
  padding: 5px 0px;
}

.foc-detail-main-content {
  text-align: left;
  width: 100%;
  padding-top: 20px;
}

.foc-section_title {
  position: relative;
  padding: 5px 0px 15px 0px;
  overflow: visible;
  width: auto;
  white-space: nowrap;
  text-align: left;
  font-family: Verdana;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}

.foc-detail-list-label {
  font-weight: bold;
}

.download-button-container {
  position: absolute;
  bottom: 20px;
  right: 40px;
}

label.bp3-label.foc-label_select {
  position: relative;
  top: -6px;
}

/** FORM STYLES **/
.foc-grid-container {
  width: 98%;
  padding-top: 2em;
}

.foc-grid-item {
  display: inline-block;
  width: 32%;
  padding: 1em 2em;
  padding-left: 0;
}

.element-conjunctions {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  width: 100%;
}

.orbit-info {
  height: 100%;
  min-height: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
