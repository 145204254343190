.focus-lateral-menu {
  &.focus-dark-side {
    background: #333 !important;
    border-radius: 0px;
  }
  .focus-menu-icon {
    color: white;
  }
}
.focus-lateral-menu.collapsed {
  min-width: 0px;
}
.focus-lateral-item {
  display: flex;
  align-items: center;
  max-width: 200px;
  line-height: 20px !important;
  &.focus-lateral-active {
    background: #666;
  }
}
.focus-full-width {
  width: 100%;
}
.focus-lateral-item.focus-lateral-item-collapsed {
  max-width: 35px;
}
