.foc-header {
	height: 50px;
    background: #333;
    text-align: left;
}

.foc-header .foc-icon_burger {
	cursor: pointer;
    padding: 20px 16px;
}

.foc-header_logo_gmv {
	padding: 16px;
}

.foc-header_logo_focusoc {
	padding: 5px;
    height: 85%;
}

.foc-header_username {
	float: right;
    margin-right: 5px;
    padding: 16px 18px;
}

.foc-username_icon {
    padding-top: 5px;
    margin-right: 10px;
}

.foc-username_icon-exit {
    cursor: pointer;
    width: 20px;
    padding-left: 0px;
    margin-left: 15px;
}

.foc-username_text {
	color: #d6d6d6;
    position: relative;
    top: -3px;
}
