html, body {
    height: 100vh;
}
#root{
    height: 100%
}
.layout-main{
    display: flex;
    height: 100%;
    flex-direction: column;
}
.layout-vertical{
    flex:1;
    flex-direction: column;
    display: flex;
}
.layout-header{
    flex: 0 1 auto;
}
.layout-lower {
    flex: 1 1 auto;
}
.layout-horizontal {
    display: flex;
}
.layout-fit{
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 1;
}
.layout-content{
    flex:1;
    position: relative;
    overflow: auto;
}
.full-height{
    height: 100%;
}
// FIXME temporal hack until a solution is implemented in blueprintjs see https://github.com/palantir/blueprint/issues/2689
.tabHolder {
    > .bp4-tab-list{    
    width: calc(100% - 40px);
    overflow: auto;
    margin-left: 20px;
}}