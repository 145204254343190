/* CSS Document */
.datainside {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 10px;
  font: normal 12px/150% Arial, Helvetica, sans-serif;
  background: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
	position: relative;
	width: 98%;
  max-width: 1200px;
}

.datainside-item {
  min-width: 400px;
  width: 48%;
}

.datainside-item .datainside {
  gap: 0px 10px;
  padding:0px;
}

.datainside-item-mini {
  min-width: 200px;
}

.arrow-up {
	display: block;
	cursor: pointer;
}

.arrow-down {
	display: block;
    padding-top: 5px;
	cursor: pointer;
}

.icons-column {
	min-width: 140px;
}

.disabled-icon-link {
  color:#e2e2e2
}