:root {
  --ag-row-hover-color: none;
}

.ag-theme-balham .ag-row-hover {
  font-weight: 500;
}

/* AG_GRID */
.ag-icon {
  font-size: 22px !important;
  background-image: unset !important;
}

.ag-popup {
  position: absolute;
}

.ag-layout-normal {
  border: none !important;
  height: 100%;
}

.ag-header {
  background: #fff !important;
}

.ag-menu {
  max-height: none !important;
}

.ag-body-viewport {
  overflow: auto;
}

.ag-react-container {
  width: 100%;
}

.customFocusocHeader {
  width: 100%;
  height: 100%;
  display: flex;
}

.customHeaderLabel {
  float: left;
  white-space: normal;
  width: 80%;
  line-height: 150%;
  padding-bottom: 0.5em;
}

.customHeaderSortIcon {
  width: 1.5em;
  height: 100%;
  bottom: 0.5em;
  right: 1em;
}

.ag-theme-balham-and-sidebar {
  width: 100%;
  position: relative;
  display: flex;
  overflow: auto;
  flex: 1;
}

.foc-table-container .ag-theme-balham-and-sidebar {
  height: 100%;
}

.ag-theme-balham {
  width: calc(100% - 32px);
  border: none !important;
}

.ag-theme-balham .ag-root-wrapper {
  border: none !important;
}

.ag-root-wrapper {
  height: 100%;
}

.ag-root-wrapper-body {
  height: 100%;
}

.ag-theme-balham .ag-paging-panel {
  margin-top: 30px;
}

.ag-theme-balham .ag-header-cell {
  border: none !important;
}

.ag-theme-balham .ag-input-wrapper input:not([type]) {
  padding: 4px 6px;
  border-color: #d8d8d8 !important;
  border-radius: 3px;
}

.ag-theme-balham .ag-floating-filter-button button {
  height: 22px !important;
  width: 22px !important;
}

.ag-theme-balham .ag-floating-filter-input {
  background-color: inherit !important;
}

.ag-theme-balham .ag-paging-panel {
  border: none;
}

.ag-ltr .ag-cell {
  border: none !important;
  line-height: 40px !important;
}

.ag-theme-balham .ag-row {
  border: none !important;
}

.ag-theme-balham .ag-row.ag-row-even {
  background-color: #f4f4f4 !important;
}

.ag-theme-balham .ag-row.ag-row-odd {
  background-color: #fff !important;
}

.ag-theme-balham .ag-row.ag-row-even.row-alert {
  background-color: #f0b7b7 !important;
}

.ag-theme-balham .ag-row.ag-row-odd.row-alert {
  background-color: #ffc1c1 !important;
}

.ag-theme-balham .ag-row.ag-row-even.row-warning {
  background-color: #f0e7b7 !important;
}

.ag-theme-balham .ag-row.ag-row-odd.row-warning {
  background-color: #fdf4c1 !important;
}

.ag-cell .icons-table {
  fill: #303030;
  vertical-align: middle !important;
  margin: 5px;
}

.icons-table-disabled {
  color: #e0e0e0;
}

/* SIDEBAR */
.foc-aggrid-sidebar {
  /* height: calc(100% - 62px); */
  width: 30px;
  bottom: 0px;
  background: #d8d8d8;
  margin-left: 3px;
  cursor: pointer;
  box-shadow: -2px 0px 3px #6d6d6d7a;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
}

.foc-aggrid-sidebar > div.open {
  padding-bottom: 12px;
  background: #f5f7f7;
  border-bottom: 1px solid #6d6d6d7a;
}

.foc-aggrid-sidebar-test-and-icon {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
}

.foc-aggrid-sidebar_icon {
  width: 20px;
  height: 20px;
  margin: 8px;
}

.foc-aggrid-sidebar_text {
  writing-mode: vertical-rl;
  margin-right: 8px;
  font-family: Verdana;
  font-size: 12px;
  margin: 5px;
}

.foc-aggrid-sidebar_container {
  /* height: calc(100% - 62px - 10px); */
  height: 100%;
  background: #f5f7f7;
  position: absolute;
  right: 33px;
  box-shadow: -2px 0px 3px #6d6d6d7a;
  overflow: hidden;
}

.foc-aggrid-sidebar_container_form {
  position: relative;
  padding: 15px 30px;
  width: 300px;
  height: 100%;
}

.foc-aggrid-sidebar_container_cb {
  max-height: 100%;
  overflow: auto;
  padding-left: 15px;
  display: grid;
}

.foc-aggrid-sidebar_container .bp3-select {
  margin-bottom: 20px;
}

.foc-aggrid-sidebar_container.foc-aggrid-sidebar_container--open {
  width: 300px;
  transition: all 1s;
  -moz-transition: all 1s;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
}

.foc-aggrid-sidebar_container.foc-aggrid-sidebar_container--close {
  width: 0;
  transition: all 1s;
  -moz-transition: all 1s;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
}

.foc-aggrid-sidebar_container .bp3-button {
  position: absolute;
  width: 240px;
  bottom: 15px;
}
